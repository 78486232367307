@import "frontend/pwa/sass/_variables.scss";

.avatar-icon-container {
  display: inline-block;
  position: relative;
}

.v-avatar {
  border-color: transparent;
  border-style: solid;
  border-width: 0;
  padding: 2px;

  img {
    border-color: white;
    border-radius: 50%;
    border-style: solid;
    border-width: 0;
  }
}

.v-icon {
  border-radius: 50%;
  position: absolute;
}

.mdi-antenna {
  height: 14px;
  width: 14px;

  &::before {
    border: 2px solid map-get($shades, 'white');
    border-radius: 50%;
  }
}
