@import "frontend/pwa/sass/_variables.scss";

$text-area-btn-radius: 28px;
$text-area-form-height: var(--height);

.text-area-form {
  font-size: 0.9rem;
  max-width: 480px;
  width: 100%;
  height: $text-area-form-height;

  .v-text-field--outlined fieldset {
    border-radius: $text-area-btn-radius 0 0 $text-area-btn-radius;
  }

  .v-textarea.v-text-field--enclosed.v-text-field--outlined.v-input--dense
  textarea {
    margin-top: 0;
    padding: 6px 0;
  }

  .v-textarea textarea {
    font-size: 0.95em;
    border-radius: 0 0 0 0 ;
    height: $text-area-form-height;
    overflow-y: hidden;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  button {
    border-radius: 0 $text-area-btn-radius $text-area-btn-radius 0px !important;
    height: $text-area-form-height !important;

    .v-progress-circular {
      color: #fff;
    }
  }
}
