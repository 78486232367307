@import "frontend/pwa/sass/_variables.scss";

.user-type {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.9;
}

.v-card-text {
  line-height: 1.1em;
}

.location::after {
  content: '\00a0';
}

.username, .location {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.follow {
  right: 8px;
}
