@import "frontend/pwa/sass/_variables.scss";

/* Inspired by: https://codepen.io/fusco/pen/XbpaYv */
.typing-indicator {
  font-size: var(--font-size);
  will-change: transform;
  width: auto;
  border-radius: 2em;
  padding: 1em;
  display: table;
  margin: 0 auto;
  animation: 2s bulge infinite ease-out;

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: -2px;
    height: 1.5em;
    width: 1.5em;
    border-radius: 50%;
    background-color: inherit;
  }
  &::after {
    height: 0.9em;
    width: 0.9em;
    left: -0.9em;
    bottom: -0.9em;
  }
  span {
    height: 0.8em;
    width: 0.8em;
    float: left;
    margin: 0 1px;
    display: block;
    border-radius: 50%;
    opacity: 0.4;
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}) {
        animation: 1s blink infinite ($i * .3333s);
      }
    }
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes bulge {
  50% {
    transform: scale(1.05);
  }
}
