$font-path: '../fonts';

@font-face {
  font-family: 'Gotham';
  font-weight: 100 300; // Thin, Light
  src: url('#{$font-path}/gotham-light-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 400; // Regular
  src: url('#{$font-path}/gotham-book.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 500; // Medium
  src: url('#{$font-path}/gotham-medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 700; // Bold
  src: url('#{$font-path}/gotham-bold-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 900; // Black
  src: url('#{$font-path}/gotham-black-regular.woff2') format('woff2');
}
