@import "frontend/pwa/sass/_variables.scss";

.comments {
  height: 1px;
  overflow-y: scroll;
  overflow-style: none;
  scrollbar-width: none;
}

.comments::scrollbar {
  display: none;
}
