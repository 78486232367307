@import "frontend/pwa/sass/_variables.scss";
@import './vuetify/_overrides.scss';

a {
  text-decoration: none;
}

/* Helper classes for app */
.container-max-width {
  max-width: $container-max-width !important;
}

.orientation-from-image {
  image-orientation: none;
  image-orientation: from-image;
}

/* Used to disable page or container scrolling */
.disable-scroll {
  overflow: hidden !important;
}

.text-decoration-none, .text-decoration-none * {
  text-decoration: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}
