@import "frontend/pwa/sass/_variables.scss";

::v-deep .v-toolbar__content {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

::v-deep .v-toolbar__title {
  width: 100%;
  padding-left: 0;
}
