@import "frontend/pwa/sass/_variables.scss";

.preview {
  position: absolute;
  height: 100%;
  width: 100%;
}

.v-btn {
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}
