@import "frontend/pwa/sass/_variables.scss";

.container {
  position: relative;
}

.stream-join {
  height: 100%;
  position: absolute;
  width: 100%;
  border: 1px solid yellow;
}

.overlay {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: map-get($grey, 'darken-3');

  &.streaming, &.ended {
    background-color: transparent;
  }
}

.overlay-top {
  align-items: center;
  height: 50px;
}
