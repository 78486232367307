@import "frontend/pwa/sass/_variables.scss";

.cover-image-container {
  position: relative;

  .image-count {
    opacity: 0.85;
    position: absolute;
    top: 0;
    right: 0;
  }
}
