@import "frontend/pwa/sass/_variables.scss";

// Make v-skeleton-loader responsive. See:
// https://github.com/vuetifyjs/vuetify/issues/11771#issuecomment-659361353
.v-skeleton-loader.v-skeleton-loader--is-loading {
  .v-skeleton-loader__image {
    height: 100%;
  }
}

// SwiperJS uses z-index: 1, reset it and isolate it to avoid conflicts.
swiper-container {
  z-index: auto !important;
  isolation: isolate;
}
