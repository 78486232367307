@import "frontend/pwa/sass/_variables.scss";

.filter {
  // Add custom background colors to outlined chips
  &.v-chip.v-chip--outlined.v-chip.v-chip {
    background-color: map-get($grey, 'lighten-3') !important;

    &.v-chip--active {
      background-color: map-get($grey, 'darken-3') !important;
      color: map-get($shades, 'white');
    }
  }
}
