.v-tab {
  text-transform: capitalize !important;
}

// Hide slide-group / tab arrow container when they are disabled
// to eliminate undesired padding
.v-slide-group__prev.v-slide-group__prev--disabled,
.v-slide-group__next.v-slide-group__next--disabled {
  display: none !important;
}
