@import "frontend/pwa/sass/_variables.scss";

.controls {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;

  .container {
    position: relative;
  }

  .sg-close {
    position: absolute;
    top: 0;
    right: 0;
  }

  .sg-close, .sg-next, .sg-prev {
    pointer-events: auto;
  }
}
