@import "frontend/pwa/sass/_variables.scss";

.action {
  display: flex;
  align-items: center;

  .text {
    font-size: 0.8em;
    color: map-get($grey, 'darken-2');
    font-weight: bold;
  }
}
