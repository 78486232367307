@import "frontend/pwa/sass/_variables.scss";

.tipping-container {
  background-color: var(--v-darkgray-base);
  border: 10px solid var(--v-lightgray-base);

  h1 {
    letter-spacing: 2px !important;
  }

  .amounts {
    .col {
      padding: 10px;

      > button {
        border: 1px solid grey;

        &.v-btn--disabled {
          color: var(--v-disabledgray-base) !important;
        }
      }
    }

    .v-btn {
      color: var(--v-lightgray-base);

      &.custom {
        color: var(--v-primary-base);
      }
    }
  }

  hr {
    border: 1px solid var(--v-lightgray-base);
  }
}
