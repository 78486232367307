@import "frontend/pwa/sass/_variables.scss";

#the-bottom-navigation {
  display: grid !important;
  grid-template-columns: repeat(
    5,
    minmax(auto, $container-max-width / 5)
  ) !important;
  > * {
    overflow: hidden;
  }

  .nav-label {
    font-size: 0.8em;
    letter-spacing: 0;
    margin-top: 3px;
  }
}
