@import "frontend/pwa/sass/_variables.scss";

$avatar-col-width: 55px;

.comment {
  padding: 1.1em;
  display: grid;
  grid-template-columns: $avatar-col-width 1fr;
}

.content {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 36px;
}

.body {
  grid-colum: 1 / 2;
}

a.username {
  text-decoration: none;
}
