@import "frontend/pwa/sass/_variables.scss";

.overlay-bottom {
  height: 50px;
}

// The message icon inside the text field
.overlay-bottom::v-deep .v-text-field .mdi-chat {
  color: map-get($shades, 'white');
  cursor: pointer;
}

// The message icon when hoving over the v-text-field before it is active
.overlay-bottom::v-deep .v-text-field--placeholder:hover .mdi-chat {
  color: var(--v-primary-base);
}

// Placeholder text in the comment text field
.overlay-bottom::v-deep .v-text-field input::placeholder {
  color: map-get($shades, 'white');
}

// The bottom underline of the comment text field when it is not active
.overlay-bottom::v-deep .v-text-field .v-input__slot::before {
  border-color: map-get($shades, 'white');
}

// The bottom underline of the comment text field when hovering over it
.overlay-bottom::v-deep .v-text-field:not(.v-input--has-state):hover
.v-input__slot::before {
  border-color: var(--v-primary-base);
}

.overlay-bottom::v-deep .v-text-field input {
  color: map-get($shades, 'white');
}

.v-form {
  width: 100%;

  .v-text-field__slot {
    color: map-get($shades, 'white');
  }
}

.disable-events {
  pointer-events: none;
}
