@import "frontend/pwa/sass/_variables.scss";

.stream {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

video {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.stream-join {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.overlay {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.ended {
  background-color: map-get($grey, 'darken-3');
}
