@import "frontend/pwa/sass/_variables.scss";

.background {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  filter: blur(20px) opacity(90%);
}
