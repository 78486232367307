@import "frontend/pwa/sass/_variables.scss";

.base-container {
  max-width: $container-max-width;
  padding: 1rem 0;
}

#app-bar > .v-toolbar__content {
  padding: 0;
}

.v-main__wrap {
  height: 100%;
}
