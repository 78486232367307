@import "frontend/pwa/sass/_variables.scss";

::v-deep .promo-video {
  .vjs-poster {
    background-position: 0 47%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.video-js {
    background: transparent !important;
    &.vjs-ended .vjs-poster {
      display: block;
    }
  }
}

.video-wrapper {
  position: relative;

  .background {
    background-image: var(--poster-img);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(10px) opacity(90%);
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
